<template>
  <div id="app">
    <doc></doc>
  </div>
</template>

<script>
import doc from './components/doc/doc.vue';

export default {
  name: 'app',
  components: {
    doc,
  },
};
</script>
